import React, { useEffect, useReducer } from "react";
import { Table, Button, Container } from 'react-bootstrap';
import "./../../index.css"
import { useOptionsState } from '../../helpers/optionsContext'
import { formatToLocal } from "../../helpers/dateHelpers";
import { Endpoint } from "../../api/base";
import EnrolPopUp from './EnrolPopUp';
import IntakePopUp from './IntakePopUp';
import ClientCloseOut from './ClientCloseOut'
import * as Swal from "sweetalert2";

const initialState = {modalShow: false, intakeList: [], intakePopUpStatus: false, intakeClickItem: {}}
function reducer (state, action)  {
    switch(action.type) {
        case 'setModalShow':
            return {...state, modalShow: action.payload}
        case 'setIntakeList':
            return {...state, intakeList: action.payload}
        case 'setIntakePopUpStatus':
            return {...state, intakePopUpStatus: action.payload}
        case 'setIntakeClickItem':
            return {...state, intakeClickItem: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

export const IntakeHistory = (props) => {
    const { data } = props
    const { ReferralInfo } = useOptionsState()
    const [state, dispatch] = useReducer(reducer, initialState, undefined)

    const openIntakePopUp = (intakeId) => {
        dispatch({ type: 'setIntakeClickItem', payload: state.intakeList[intakeId] })
        dispatch({ type: 'setIntakePopUpStatus', payload: true })
    };
    const closeIntakePopUp = () => {
        dispatch({ type: 'setIntakePopUpStatus', payload: false })
    };

      useEffect(() => {
        const getData = () => {
            Endpoint.Clients.CloseOut.history(data.id).then(result => {
                let intakeData = result.data.map((data) => {
                    let referralText = ''
                    data.referralInfoIds.forEach((value, ) => {
                        if(ReferralInfo[value]){
                            referralText += `${ReferralInfo[value].replace(/_/g, " ")}, `
                        }
                    })
                    return {
                        ...data,
                        closeOutEmployeeText: data.closeOutEmployee?data.closeOutEmployee:'TBD',
                        referralText: referralText
                    }
                })
                const sortedDate = intakeData.sort((a, b) => {
                    return new Date(a.intakeDate).getTime() -
                        new Date(b.intakeDate).getTime()
                }).reverse();
                dispatch({ type: 'setIntakeList', payload: sortedDate })
            }).catch(err => {
                //Failed to fetch intake history
                Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
              console.error(err)
            })
        }
        getData()
      }, [ReferralInfo, data]);

    return (
        <>

            {state.intakeList && state.intakeList.length ?

            <Container>
                <div style={{ display: "flex", alignItems: "left" }}>
                    {(data.accountStatusId && data.accountStatusId !== 1)?(
                        <Button variant="primary" onClick={() => dispatch({ type: 'setModalShow', payload: true })}>
                            Re-Enroll
                        </Button>
                    ):(
                        <ClientCloseOut clientId={data.id} />
                    )}

                <EnrolPopUp show={state.modalShow} onHide={() => dispatch({ type: 'setModalShow', payload: false })} data={props.data}/>
                <IntakePopUp clientId={data.id} closeMe={closeIntakePopUp} status={state.intakePopUpStatus} intakeDetails={state.intakeClickItem}/>
                </div>
                <div>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th className="border-top-0 text-left">Bin number</th>
                            <th className="border-top-0 text-center">Intake Date</th>
                            <th className="border-top-0 text-center">Closing Out Date</th>
                            <th className="border-top-0 text-left">Checkin Employee</th>
                            <th className="border-top-0 text-left">Closing Out Employee</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.intakeList.map((item, index) => (
                            <tr className="table-row" key={item.clientId || index}  onClick={() => openIntakePopUp(index)} style={{ cursor: "pointer" }}>
                                <td className="text-left">{`${item.binNumber}`}</td>
                                <td className="text-left">{`${formatToLocal(item.intakeDate+"Z")}`}</td>
                                <td className="text-left">{`${item.closeOutDate?formatToLocal(item.closeOutDate+"Z"):"TBD"}`}</td>
                                <td className="text-left">{`${item.checkInEmployee}`}</td>
                                <td className="text-left">{`${item.closeOutEmployeeText}`}</td>
                                {/* <td className="text-left">{ReferralInfo[item.referralId]?.replace(/_/g, " ")}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
                : <p>Not enough list to display.</p>
            }
        </>
    );

}


export default IntakeHistory;
