import React, {useEffect, useReducer} from "react";
import ClientDetails from "./ClientDetails";
import "./ClientProfile.css";
import {Link, useParams, withRouter} from "react-router-dom";
import ClientCloseOut from './ClientCloseOut'
import ClientCheckInHistory from "./ClientCheckInHistory"
import ClientInventoryHistory from "./ClientInventoryHistory"
import { Row, Col, Tab, Tabs, Button} from 'react-bootstrap' 
import CheckinForm from "../../pages/CheckinForm"
import {baseURL, Endpoint} from '../../api/base'
import { useOptionsState } from '../../helpers/optionsContext'
import { IntakeHistory }  from "./IntakeHistory"
import EnrolPopUp from "./EnrolPopUp";

function reducer (state, action)  {
  switch(action.type) {
    case 'setModalShow':
      return {...state, modalShow: action.payload}
    case 'setProfilePicture':
      return {...state, profilePicture: action.payload}
    default:
      throw new Error("Something went wrong!")
  }
}

function ClientProfile(props) {
  const { AccountStatus, CheckInType, HistoryAction } = useOptionsState()
  const [state, dispatch] = useReducer(reducer, {setModalShow: false, profilePicture: undefined}, undefined)
  let { id, tabKey } = useParams();

  const onSelectTabs = (tab) => {
    props.history.push(`/app/clients/${id}/${tab}`)
  }

  useEffect(() => {
    Endpoint.Clients.getImage(id).then(res => {
      dispatch({ type: 'setProfilePicture', payload: URL.createObjectURL(res.data)});
    }).catch(e => {
    })
  }, [])

  return (
      <Row className="m-0">
        <Col xs={12} md={3} className="py-3" >
          <div className="left">

            <div className="user-info">
              {/*<img src={`${baseURL}client/DownloadImage/${props.data?.id}?t=${new Date().getTime()}`} alt="Profile" width="100" />*/}
              <img src={state.profilePicture} alt="Profile" width="100"/>
              <h4>{props.data.firstName + " " + props.data.lastName}</h4>
              <p>Bin Number : <span className="font-weight-bold">{props.data?.bin?.binNumber}</span></p>
              <p>Clarity Id : <span className="font-weight-bold">{props.data.clarityId}</span></p>
              <p>User Status: <span className={`font-weight-bold ${props?.data?.accountStatusId === 1 && "text-success"}`}>
                {props.data.accountStatusId?AccountStatus[props.data.accountStatusId].replace(/_/g, " "):"-"} </span>
              </p>
            </div>

            <EnrolPopUp show={state.modalShow} onHide={() => dispatch({ type: 'setModalShow', payload: false })} data={props.data}/>
            <div className="profile-actions py-2 py-lg-4">
              {props.data.accountStatusId === 1?
                  <div style={{ marginBottom: '10px' }}>
                    <div className="action">
                      <ClientCloseOut className="w-100 mb-3" clientId={props.data.id}/>
                    </div>
                    <div className="action">
                      <Link to={`/app/check-in-form/${props.data.id}`} >
                        <Button variant="outline-success" size="sm">Check in</Button>
                      </Link>
                    </div>
                  </div>
                  :
                  <div className="action">
                    <Button variant="primary" size="sm" onClick={() => dispatch({ type: 'setModalShow', payload: true })}>
                      Re-Enroll
                    </Button>
                  </div>}
              <div className="action">
                <Link to={`/app/edit-client/${props.data.id}`}>
                  <Button variant="outline-dark" size="sm" >Edit Profile</Button>
                </Link>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={9} className="py-3">
          <div className="right py-2 h-100">
          <Tabs
              className="client-profile-tabs"
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              activeKey={tabKey}
              onSelect={(k) => onSelectTabs(k)}
          >
            <Tab  eventKey="profile" title="Profile">
              <div className="client_profile__tab_container">
              <ClientDetails data={props?.data}/>
              </div>
            </Tab>
            <Tab eventKey="history" title="Check In History">
              <div className="client_profile__tab_container">
                <ClientCheckInHistory history={props.history} clientId={props.data.id} data={props?.data?.checkIn} types={CheckInType} />
              </div>
            </Tab>
            <Tab eventKey="IntakeHistory" title="Intake History">
              <div className="client_profile__tab_container">
                <IntakeHistory data={props.data}/>
              </div>
            </Tab>
            <Tab eventKey="Inventory" title="Inventory List">
              <div className="client_profile__tab_container">
                <CheckinForm getInventoryHistory={props?.getInventoryHistory} inventory={props?.data?.inventory} clientId={props?.data?.id}/>
              </div>
            </Tab>
            <Tab eventKey="inventoryHistory" title="Inventory History">
              <div className="client_profile__tab_container">
                <ClientInventoryHistory clientId={props.data.id} data={props?.inventoryHistory} types={HistoryAction} />
              </div>
            </Tab>
          </Tabs>
          </div>
        </Col>
      </Row>
  );
}

export default withRouter(ClientProfile);
