import { format } from 'date-fns'
export const formatToLocal = date => {
    try {
        return format(new Date(date), "MM/dd/yyyy h:mm a")
    }catch (e) {
        return date
    }
}

export const formatToLocalWoT = date => {
    try {
        return format(new Date(date), "MM/dd/yyyy")
    }catch (e) {
        return date
    }
}
