import React, { useEffect, useReducer } from "react";
import {Table, Pagination, Button} from 'react-bootstrap';
import { Endpoint } from "../../api/base";
import {formatToLocalWoT} from "../../helpers/dateHelpers";
import Swal from "sweetalert2";
import {ConfirmModal} from "../WaitList/ConfirmModal";

const textColor = {
    "1": "text-success",
    "2": "text-danger",
    "3": "text-primary"    
}

const initialState = {fetchedData: [], currentPage: 0, inventoryId: undefined, showDeleteConfirm: false, loading: false}
function reducer (state, action)  {
    switch(action.type) {
        case 'setFetchedData':
            return {...state, fetchedData: action.payload}
        case 'setCurrentPage':
            return {...state, currentPage: action.payload}
        case 'setInventoryId':
            return {...state, inventoryId: action.payload}
        case 'setShowDeleteConfirm':
            return {...state, showDeleteConfirm: action.payload}
        case 'setLoading':
            return {...state, loading: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

export const ClientInventoryHistory = ({ clientId, data, types }) => {
    const [state, dispatch] = useReducer(reducer, initialState, undefined)
    const itemPerPage = parseInt(localStorage.getItem("sc_tables_spp") || 10, 10)

    useEffect(() => {
        if (!data) {
            Endpoint.Inventory.history(clientId).then(res => {
                dispatch({ type: 'setFetchedData', payload: res.data })
            }).catch(err => {
            })
        }
    }, [data, clientId])

    const list = (data || state.fetchedData).sort((a, b) => {
        return new Date(a.dateTime).getTime() -
            new Date(b.dateTime).getTime()
    }).reverse();

    const openDelete = (inventoryId) => {
        dispatch({ type: 'setInventoryId', payload: inventoryId });
        dispatch({ type: 'setShowDeleteConfirm', payload: true });
    }

    const handleDeleteInventory = () => {
        dispatch({ type: 'setLoading', payload: true });
        Endpoint.Inventory.delete(state.inventoryId).then(() => {
            dispatch({ type: 'setShowDeleteConfirm', payload: false });
            dispatch({ type: 'setLoading', payload: false });
            window.location.reload();
        }).catch(err => {
            //failed to delete!
            Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error');
            dispatch({ type: 'setLoading', payload: false });
        })
    }

    const numberOfPages = Math.ceil(list.length / itemPerPage)
    const pages = (new Array(numberOfPages)).fill(0)
    return (
        <>
            {list && list.length ?
                <>
                    <ConfirmModal
                        loading={state.loading}
                        text="Are you sure you want to delete this Inventory History?"
                        isOpen={state.showDeleteConfirm} handleClose={() => dispatch({ type: 'setShowDeleteConfirm', payload: false })}
                        onConfirm={handleDeleteInventory}/>
                <Table>
                    <thead>
                        <tr>
                            {/* <th>#</th> */}
                            <th>History Action</th>
                            <th>Subject</th>
                            <th>Description</th>
                            <th>Employee</th>
                            <th>Date</th>
                            <th className="border-top-0">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                            {list.slice(state.currentPage * itemPerPage, (state.currentPage + 1) * itemPerPage).map((item, index) => (
                            <tr key={item.id || index}>
                                {/* <td>{(currentPage * itemPerPage + index) + 1}</td> */}
                                <td className={textColor[item?.historyActionId]}>{types[item.historyActionId]}</td>
                                <td>{item.subject}</td>
                                <td>{item.value}</td>
                                <td>{item.employee || "-"}</td>
                                <td>{formatToLocalWoT(item.dateTime)}</td>
                                <td><Button variant="outline-danger" onClick={() => openDelete(item.id)} size="sm">Delete</Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Pagination size="sm">
                        <Pagination.First disabled={state.currentPage ===0} onClick={()=>{dispatch({ type: 'setCurrentPage', payload: 1 })}} />
                        <Pagination.Prev disabled={state.currentPage===0} onClick={() => { dispatch({ type: 'setCurrentPage', payload: state.currentPage - 1 }) }} />
                        {pages.map((val,idx)=>(
                            <Pagination.Item key={idx} active={idx === state.currentPage} onClick={() => { dispatch({ type: 'setCurrentPage', payload: idx }) }}>{idx}</Pagination.Item>
                        ))}
                        <Pagination.Next disabled={state.currentPage === numberOfPages -1} onClick={() => { dispatch({ type: 'setCurrentPage', payload: state.currentPage + 1 }) }} />
                        <Pagination.Last disabled={state.currentPage === numberOfPages -1} onClick={() => { dispatch({ type: 'setCurrentPage', payload: numberOfPages }) }} />
                </Pagination>
                </>
                : <p>Not enough data to display.</p>
            }
        </>
    );

}


export default ClientInventoryHistory;
