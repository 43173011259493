
import React, {useEffect, useReducer} from "react";
import {Row, Col, Modal, Form} from 'react-bootstrap';
import {formatToLocal} from "../../helpers/dateHelpers";
import {useOptionsState} from "../../helpers/optionsContext";
import {Endpoint} from "../../api/base";

function reducer (state, action)  {
    switch(action.type) {
        case 'setNotes':
            return {...state, notes: action.payload}
        default:
            throw new Error("Something went wrong!")
    }
}

export default function IntakePopUp(props) {
    const { clientId } = props

    const { CloseOutReasonType, ReferralInfo, ReferralOutCode, AccountStatus, TermsOfServiceLength } = useOptionsState()
    const { status, closeMe, intakeDetails } = props;
    const [state, dispatch] = useReducer(reducer, {notes: []}, undefined)

    useEffect(() => {
        const fetchData = async () => {
            const response = await Endpoint.Clients.Notes.get(clientId)
            dispatch({ type: 'setNotes', payload: response.data })
        };
        fetchData();
    }, [clientId])

    return (
        <>

            <Modal show={status} size="lg" onHide={closeMe}>
                <Modal.Header closeButton>
                    <Modal.Title>Intake Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Bin Number</Form.Label>
                        <Form.Text className="text-muted">
                            {intakeDetails.binNumber}
                        </Form.Text>
                    </Form.Group>
                    <Row>
                        <Col xs={4} md={4}>
                            <div className="field_element">
                                <label>Intake Date</label>
                                <Form.Text className="text-muted">
                                    {`${formatToLocal(intakeDetails.intakeDate+"Z")}`}
                                </Form.Text>
                            </div>
                        </Col>
                        <Col xs={4} md={4}>
                            <div className="field_element">
                                <label>Check In Employee</label>
                                <Form.Text className="text-muted">
                                    {`${intakeDetails.checkInEmployee}`}
                                </Form.Text>
                            </div>
                        </Col>
                        <Col xs={4} md={4}>
                            <div className="field_element">
                                <label>Referral In Code</label>
                                {intakeDetails.referralInfoIds  && intakeDetails.referralInfoIds.length ?(
                                    intakeDetails.referralInfoIds?.map((item) => (
                                        <Form.Text className="text-muted">
                                            {ReferralInfo[(item)]?.replace(/_/g, " ")}
                                        </Form.Text>
                                    ))
                                ):(
                                    <Form.Text className="text-muted">
                                        None
                                    </Form.Text>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} md={4}>
                            <div className="field_element">
                                <label>Closing Out Date</label>
                                <Form.Text className="text-muted">
                                    {`${intakeDetails.closeOutDate?formatToLocal(intakeDetails.closeOutDate+"Z"):"TBD"}`}
                                </Form.Text>
                            </div>
                        </Col>
                        <Col xs={4} md={4}>
                            <div className="field_element">
                                <label>Closing Out Employee</label>
                                <Form.Text className="text-muted">
                                    {`${intakeDetails.closeOutEmployeeText}`}
                                </Form.Text>
                            </div>
                        </Col>
                        <Col xs={4} md={4}>
                            <div className="field_element">
                                <label>Referral Out Code</label>
                                {intakeDetails.referralOutCodeIds && intakeDetails.referralOutCodeIds.length ? (
                                    intakeDetails.referralOutCodeIds?.map((item) => (
                                        <Form.Text className="text-muted">
                                            {ReferralOutCode[(item)]?.replace(/_/g, " ")}
                                        </Form.Text>
                                    ))
                                ):(
                                    <Form.Text className="text-muted">
                                        None
                                    </Form.Text>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} md={4}>
                            <div className="field_element">
                                <label>Closing Out Reason</label>
                                {intakeDetails.closeOutReasons && intakeDetails.closeOutReasons.length ? (
                                    intakeDetails.closeOutReasons?.map((item) => (
                                        <Form.Text className="text-muted">
                                            {CloseOutReasonType[(item.closeOutReasonTypeId)]?.replace(/_/g, " ")}
                                            {CloseOutReasonType[(item.closeOutReasonTypeId)] === "Other" ? " :" + item.otherReason : ""}
                                        </Form.Text>
                                    ))
                                ):(
                                    <Form.Text className="text-muted">
                                        None
                                    </Form.Text>
                                )}
                            </div>
                        </Col>
                        <Col xs={4} md={4}>
                            <div className="field_element">
                                <label>Term of Service Agreement Length</label>
                                <Form.Text className="text-muted">
                                    {TermsOfServiceLength[(intakeDetails.termsOfServiceLengthId)]} Days
                                </Form.Text>
                            </div>
                        </Col>
                    </Row>
                    <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <Form.Text className="text-muted">
                            {AccountStatus[(intakeDetails.accountStatusId)]?.replace(/_/g, " ")}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Notes</Form.Label>
                            {/*{`${state.notes?.filter(item => (*/}
                            {/*    item.noteTypeId === 1*/}
                            {/*))[0]?.value || "null"}`}*/}
                            {state.notes?.filter(item => (
                                item.noteTypeId === 1
                            ))?.map((item) => (
                                <Form.Text className="text-muted">
                                    {item.value}
                                </Form.Text>
                            ))}
                    </Form.Group>

                </Modal.Body>
            </Modal>
        </>
    );
}


